import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output
} from '@angular/core';
import { UserService } from '../../user.service';
import { Angulartics2 } from 'angulartics2';
import { AppService } from 'src/app/app.service';
import { Subscription } from 'rxjs';
import { User } from 'src/app/shared/interfaces/user';
import { AgePipe } from 'src/app/shared/pipe/age.pipe';

@Component({
  selector: 'app-account-profil-infos',
  templateUrl: './account-profil-infos.component.html',
  styleUrls: ['./account-profil-infos.component.scss']
})
export class AccountProfilInfosComponent implements OnInit {
  @ViewChild('slider', { read: ElementRef, static: false }) slider: ElementRef;
  @Input() me: User;
  @Output() saveChanges = new EventEmitter<any[]>();

  shouldSendPresentationTracker = false;
  hiddenPassword = true;
  agePipe = new AgePipe();
  disabled = true;
  submitted = false;
  datasSaved = false;
  previousDescription: string;
  forms = {
    description: { focus: false },
    age: { focus: false }
  };
  meTmp = null;

  valueSubscription: Subscription;
  getUserAge(user: User): number {
    return this.agePipe.transform(user.birthdate);
  }
  setUserAge(age: number) {
    return this.agePipe.birthdateFromAge(age);
  }

  constructor(private app: AppService) {}

  ngOnInit() {
    this.meTmp = {
      nickname: this.me.nickname,
      email: this.me.email,
      phone: this.me.phone,
      password: this.me.password,
      description: this.me.description,
      birthdate: this.me.birthdate,
      city: this.me.city,
      hobbies: this.me.hobbies
    };
    this.me.age = this.getUserAge(this.me);
    if (!this.me.description || this.me.description === null || this.me.description.length === 0) {
      this.shouldSendPresentationTracker = true;
    }
    this.previousDescription = this.me.description;
  }
  descriptionListener(event: any): void {
    const textarea = event.target as HTMLTextAreaElement;
    const val = textarea.value;
    if (val !== this.previousDescription) {
      this.saveChanges.emit(['description', val]);
    } else {
      this.saveChanges.emit(['description', null]);
    }
  }
  ageListener(event: any): void {
    if (this.me.age !== event.value) {
      this.saveChanges.emit(['birthdate', this.agePipe.birthdateFromAge(event.value)]);
    }
  }
  setBlurClass(el: string) {
    this.forms[el].focus = false;
  }
  setFocusClass(el: string) {
    if (window.innerWidth <= 960) {
      this.app.showInput(this.me[el], false);
      this.valueSubscription = this.app.state.subscribe(res => {
        this.disabled = false;
        console.log(res);
        if (res.value !== this.previousDescription) {
          this.me.description = res.value;
          this.saveChanges.emit(['description', res.value]);
        }
        this.valueSubscription.unsubscribe();
      });
    } else {
      this.forms[el].focus = true;
    }
  }
  setCity($event) {
    if ($event.id !== this.me.city.id) {
      this.saveChanges.emit(['city', $event]);
    }
    this.me.city = $event;
  }
}
