<div class="subscription">
<h1 i18n="titre creer un compte@@SignUp">Créer votre compte</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <h2 i18n="Label de la sélection du sexe @@SignUpYouAre">Vous êtes :</h2>
  <div fxLayout="row" class="subscription-gender">
    <button type="button" class="button button-icon darken" [class.accent]="user.gender === Gender.man"
      (click)="setGender(Gender.man)"
      angulartics2On="click" angularticsAction="/vpv/subscription_1step_gender_choosen_V3"
      angularticsCategory="man">
      <mat-icon svgIcon="man"></mat-icon>
      <p i18n="Vous êtes un homme @@SignUpGenderMan">Un homme</p>
    </button>
    <button type="button" class="button button-icon darken" [class.accent]="user.gender === Gender.woman"
      (click)="setGender(Gender.woman)"
      angulartics2On="click" angularticsAction="/vpv/subscription_1step_gender_choosen_V3"
      angularticsCategory="woman">
      <mat-icon svgIcon="woman"></mat-icon>
      <p i18n="Vous êtes un homme @@SignUpGenderWoman">Une femme</p>
    </button>
  </div>
  <!-- <h2 i18n="Les identifiants de l'utilisateur @@NoIDauto"> Merci de saisir ici des nouveaux identifiants afin de personnaliser votre compte.
    Ces identifiants doivent être différents de ceux reçus par SMS. </h2> -->
    <h2 i18n="Les identifiants de l'utilisateur @@UserConnexionId">Créez vos identifiants de connexion :</h2>

  <ng-container *ngIf="form.get('email')">
    <label>
      <mat-icon svgIcon="man" class="prefix"></mat-icon>
      <mat-form-field>
        <input matInput type="text" placeholder="Votre email" formControlName="email"
          i18n-placeholder="Placeholder du champs email du parcours@@EmailLabel" required
          (input)="sendEmailTracker($event)">
        <mat-icon matSuffix svgIcon="pencil"></mat-icon>
        <!-- <mat-error *ngIf="form.get('email').hasError('email')" i18n="erreur de mauvais format d'email@@NotEmailError">
          Vous devez renseigner un email
        </mat-error> -->
        <mat-error i18n="erreur lors que l'email n'est pas saisie @@EmailRequired" *ngIf="form.get('email').hasError('required')">
          L'email est <strong>requis</strong>
        </mat-error>
      </mat-form-field>
    </label>
  </ng-container>

  <ng-container *ngIf="form.get('phone')">
    <label>
      <mat-icon svgIcon="man" class="prefix"></mat-icon>
      <mat-form-field>
        <input type="text" matInput placeholder="Votre téléphone" i18n-placeholder="Placeholder du champs téléphone du parcours@@PhoneLabel" required
          [textMask]="{mask: mask, placeholderChar: '\u2000', guide: false}" formControlName="phone">
        <mat-icon color="accent" matSuffix svgIcon="pencil"></mat-icon>
      </mat-form-field>
    </label>
  </ng-container>

  <label>
    <mat-icon svgIcon="key" class="prefix"></mat-icon>
    <mat-form-field>
      <input matInput type="password" formControlName="password" [type]="hide ? 'password' : 'text'"
        i18n-placeholder="Placeholder du champs mot de passe du formulaire de connexion@@PasswordPlaceholder"
        placeholder="Mot de passe" required
        (input)="sendPasswordTracker($event)">
      <mat-icon matSuffix (click)="hide = !hide" [svgIcon]="hide ? 'visible' : 'hide'"></mat-icon>
      <mat-error *ngIf="form.get('password').hasError('required')"
        i18n="Lors de la connexion le user ne complète pas son mot de passe@@ConnexionPasswordEmpty">Veuillez
        entrer un mot de passe valide</mat-error>
    </mat-form-field>
  </label>
  <div class="baseline" i18n="@@TooShortPassword">bad password {{min}} {{max}}</div>

  <button class="button button-finger accent" type="submit"
  [disabled]="form.invalid" i18n="se connecter au tchat@@ConnectToChat">
    Se Connecter au Tchat
  </button>
</form>
<a routerLink="/connection" i18n="Déjà un compte@@AlreadySubscribe">Déjà un compte</a>
</div>
