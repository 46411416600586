import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SignUpType } from 'src/app/shared/enums/sign-up-type.enum';
import { UserService } from 'src/app/account/user.service';
import { ActivatedRoute } from '@angular/router';
import { isNumber } from 'util';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  /** Sign-up methods enum */
  readonly SignUpType = SignUpType;

  /** The site sign-up type */
  signUpType = environment.useBothSignUpMethod ? SignUpType.both : SignUpType.email;
  email: string;
  submitted = false;
  success = false;

  constructor(
    private user: UserService,
    private route: ActivatedRoute,
    private dialog: AppService,
    private users: UserService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if (params.get('login')) {
        this.email = params.get('login');
        this.users.showSubscribing(true);
      }
    });
  }

  onSubmit() {
    if (this.email && this.email !== '') {
      let readyToSend = true;
      if (environment.extCode === 'AR') {
        readyToSend = isNaN(Number(this.email));
      }
      if (readyToSend) {
        this.submitted = true;
        this.user.sendPasswordRecovery(this.email).subscribe(res => {
          this.submitted = false;
          this.success = res;
        });
      } else {
        this.dialog.throwError({
          message: 'error',
          code: 302,
          error: 'EmailRequired'
        });
      }
    }
  }
}
