import { Component, OnInit, Inject } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/account/user.service';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  login: string;
  password: string;

  key = CryptoJS.enc.Hex.parse('0123456789abcdef0123456789abcdef');
  iv = CryptoJS.enc.Hex.parse('abcdef9876543210abcdef9876543210');

  facebook = null;
  twitter = null;
  instagram = null;

  loading = false;
  lp = environment.LP;
  free = environment.freeSubscription;

  constructor(
    private route: ActivatedRoute,
    private user: UserService,
    private router: Router,
    private dialog: AppService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.facebook = {
      available: environment.home.facebook.available,
      link: environment.home.facebook.link
    };
    this.twitter = {
      available: environment.home.twitter.available,
      link: environment.home.twitter.link
    };
    this.instagram = {
      available: environment.home.instagram.available,
      link: environment.home.instagram.link
    };
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if (params.get('login')) {
        this.login = params.get('login');
        this.dialog.throwError({ message: this.login, code: '', error: 'AlreadyCreated' });
        this.user.showSubscribing(true);
      }
    });
  }
  onSubmit(): void {
    this.loading = true;
    // Impementing the Key and IV and encrypt the password+
    const encryptedPassword = CryptoJS.AES.encrypt(this.password, this.key, {
      mode: CryptoJS.mode.CBC,
      iv: this.iv
    });
    this.user.signIn(this.login, encryptedPassword).subscribe(ok => {
      console.log('here is the return', ok);
      this.loading = false;
      if (ok) {
        this.router.navigate(['/chat']);
      }
    });
  }
}
