import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from './account/token.service';
import { UserService } from './account/user.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedGuard implements CanActivate {
  constructor(private router: Router, private token: TokenService, private users: UserService) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const hasToken = !!this.token.get();

    if (hasToken && next.routeConfig.path === 'connection') {
      return of(this.router.parseUrl('/chat'));
    } else if (next.routeConfig.path === 'completeProfile') {
      return this.users.checkRegistration(next.queryParams).pipe(
        map(({ redirectPage, user, msisdn }) => {
          //   user = {
          //     id: 3933142,
          //     nickname: null,
          //     email: 'Bryandu144@gmail.com',
          //     phone: null
          // };
          switch (redirectPage) {
            case 'external':
              window.open(environment.LP, '_self');
              return false;
            case 'connexion':
              return this.router.parseUrl(`/connection;login=${user.email || user.phone}`);
            default:
              this.users.msisdn = msisdn;
              return true;
          }
        }),
        catchError(() => of(false))
      );
    }
    return of(true);
  }
  canActivateChild(): Observable<boolean | UrlTree> {
    const hasToken = !!this.token.get();

    if (!hasToken) {
      return of(this.router.parseUrl('/connection'));
    }

    return of(true);
  }
}
