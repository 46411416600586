import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { TokenService } from '../account/token.service';
import { User } from '../shared/interfaces/user';
import { ApiResponse } from '../shared/interfaces/api-response';
import { LookingFor } from '../shared/enums/looking-for.enum';
import { Filters } from '../shared/interfaces/filters';

/**
 * User search service
 *
 * This service is used to resolve MeetUp research
 */
@Injectable({
  providedIn: 'root'
})
export class UserSearchService {
  constructor(private http: HttpClient, private token: TokenService) {}

  meetUp(limit = 100, offset = 0): Observable<User[]> {
    const params = new HttpParams()
      .set('key', environment.key.toString())
      .set('userauth', this.token.get())
      .set('offset', offset.toString())
      .set('limit', limit.toString())
      .set('country', environment.extCode);
    return this.http
      .get<ApiResponse<{ users: User[]; totalCount: number }>>(
        `${environment.api}/get_users_list`,
        { params }
      )
      .pipe(
        map(res => {
          return res.data.users;
        })
      );
  }

  meetUpSearch(user: User, limit = 100, offset = 0): Observable<User[]> {
    if (!user.search.city) {
      user.search.city = user.city;
    }
    const params = new HttpParams()
      .set('key', environment.key.toString())
      .set('userauth', this.token.get())
      .set('offset', offset.toString())
      .set('limit', limit.toString())
      .set('country', environment.extCode)
      .set('chatuser_id', user.id.toString())
      .set('city_id', user.search.city.id.toString())
      .set('distance', user.search.distance.toString())
      .set('looking_for', user.search.looking_for.toString())
      .set('min_age', user.search.min_age.toString())
      .set('max_age', user.search.max_age.toString())
      .set('has_photo', user.search.has_photo.toString());
    return this.http
      .post<ApiResponse<{ users: User[]; totalCount: number }>>(
        `${environment.api}/get_users_by_filters`,
        { params }
      )
      .pipe(
        map(res => {
          if (res.error) {
            console.error(res);
            throw new Error(res.msg as string);
          }
          return res.data.users;
        })
      );
  }
  presentationList(): Observable<User[]> {
    const params = new HttpParams()
      .set('key', environment.key.toString())
      .set('userauth', this.token.get())
      .set('offset', '0')
      .set('limit', '64')
      .set('country', environment.extCode);
    return this.http
      .get<ApiResponse<{ users: User[]; totalCount: number }>>(
        `${environment.api}/get_users_list_for_presentation`,
        { params }
      )
      .pipe(
        map(res => {
          return res.data.users;
        })
      );
  }
  getSearchFilters(): Observable<Filters> {
    const params = new HttpParams()
      .set('key', environment.key.toString())
      .set('userauth', this.token.get())
      .set('country', environment.extCode);
    return this.http
      .get<ApiResponse<{ options: Filters }>>(`${environment.api}/get_chatuser_looking_for`, {
        params
      })
      .pipe(
        map(res => {
          console.log('getSearchFilters', res.data.options);
          return res.data.options;
        })
      );
  }

  setSearchFilters(filters: Filters): Observable<void> {
    const body = {
      key: environment.key.toString(),
      userauth: this.token.get(),
      city_id: filters.city.id.toString(),
      distance: filters.distance.toString(),
      looking_for: filters.looking_for,
      min_age: filters.min_age.toString(),
      max_age: filters.max_age.toString(),
      has_photo: filters.has_photo.toString()
    };
    return this.http
      .put<ApiResponse<void>>(`${environment.api}/set_chatuser_looking_for`, body)
      .pipe(
        map(res => {
          return;
        })
      );
  }
}
