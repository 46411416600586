import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { City } from '../interfaces/city';
import { environment } from '../../../environments/environment';
import { ApiResponse } from '../interfaces/api-response';
/**
 * City search service
 *
 * This service is used to search cities for the autocompleted city input
 */
@Injectable({
  providedIn: 'root'
})
export class CitySearchService {
  constructor(private http: HttpClient) {}

  byName(limit: number, name?: string): Observable<City[]> {
    let params = new HttpParams()
      .set('key', environment.key.toString())
      .set('offset', '0')
      .set('limit', limit.toString())
      .set('country', environment.extCode);
    if (name) {
      params = params.set('city_name', name);
    }
    return this.http
      .get<ApiResponse<{ cities: City[] }>>(`${environment.api}/all_cities`, { params })
      .pipe(
        map(res => {
          return res.data ? (res.data.cities as City[]) : [];
        })
      );
  }
}
