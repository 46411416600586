import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { City } from '../interfaces/city';
import { environment } from 'src/environments/environment';
import { CitySearchService } from '../city-input/city-search.service';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-focus-input',
  templateUrl: './focus-input.component.html',
  styleUrls: ['./focus-input.component.scss']
})
export class FocusInputComponent implements OnInit, AfterViewChecked {
  @Input() val: string | City;
  @Input() call: boolean;
  @ViewChild('inputFocus', { static: false }) input: ElementRef;

  cities: City[];
  inputValue: string;
  hasAutoComplete = environment.showAllCities;

  set newVal(value: string) {
    this.inputValue = value;
    this.app.change(this.inputValue);
    if (this.call) {
      if (this.hasAutoComplete) {
        this.searchCities.byName(250).subscribe(cities => (this.cities = cities));
      } else {
        this.searchCities.byName(10, value as string).subscribe(cities => (this.cities = cities));
      }
    }
  }
  get newVal(): string {
    return this.inputValue;
  }

  constructor(private app: AppService, private searchCities: CitySearchService) {}

  ngOnInit() {
    this.inputValue = this.val as string;
    if (this.call) {
      if (this.hasAutoComplete) {
        this.searchCities.byName(250).subscribe(cities => (this.cities = cities));
      } else {
        this.searchCities
          .byName(10, this.val as string)
          .subscribe(cities => (this.cities = cities));
      }
    }
  }
  ngAfterViewChecked(): void {
    this.input.nativeElement.focus();
  }
  blurInput() {
    this.app.hideInput(this.newVal);
  }
  setCity(city: City) {
    this.app.hideInput(city);
  }
}
