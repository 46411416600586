<div class="main-background" [class.logged]="logged" [class.desktop]="desktop" [class.rtl]="rtl"
  [class.offline]="!online"></div>
<main [ngClass]="[ desktop ? 'desktop' : 'mobile', rtl ? 'rtl' : 'ltr' ]" [class.offline]="!online"
  [dir]="rtl ? 'rtl' : 'ltr'">
  <app-account *ngIf="logged"></app-account>
  <div class="main-content" [class.logged]="logged">
    <app-conversations *ngIf="logged else home"></app-conversations>
  </div>
  <app-flap [desktop]="desktop" [logged]="logged"></app-flap>
</main>
<ng-template #home>
  <ng-container *ngIf="online else offline">
    <app-home></app-home>
  </ng-container>
</ng-template>
<app-notifications></app-notifications>
<app-error *ngIf="error.display" [message]="error.message" [code]="error.code" [error]="error.error"></app-error>
<app-block *ngIf="popup.display" [popup]="popup"></app-block>
<app-focus-input *ngIf="showInputFocus.show" [val]="showInputFocus.value" [call]="showInputFocus.call">
</app-focus-input>
<footer *ngIf="footerDisplay">
  <button class="legal button button-round lighten right" (click)="toggleLegal()"
    *ngIf="(!legalDisplay) && footerDisplay">
    <mat-icon svgIcon="information"></mat-icon>
  </button>
  <button class="legal button button-icon lighten right" (click)="toggleLegal()" *ngIf="legalDisplay">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
  <app-footer (hideLegal)="toggleLegal()" [displayBanner]="displayBanner" *ngIf="legalDisplay"></app-footer>
</footer>
<ng-template #offline>
  <div class="banner-offline" *ngIf="!online">
    <mat-icon svgIcon="nointernet"></mat-icon>
    <ng-container i18n="@@Internetconnexionrequired">Vous n'êtes pas connecté à un réseau ...</ng-container>
  </div>
</ng-template>
<!-- <ng-template>
<div i18n="@@HOBBY_SPORT">sport</div>
<div i18n="@@HOBBY_TRAVEL">message</div>
<div i18n="@@HOBBY_COOKING">message</div>
<div i18n="@@HOBBY_MUSIC">message</div>
<div i18n="@@HOBBY_CINEMA">message</div>
<div i18n="@@HOBBY_NATURE">message</div>
<div i18n="@@HOBBY_PARTY">message</div>
<div i18n="@@HOBBY_PAINTING">message</div>
<div i18n="@@HOBBY_THEATRE">message</div>
<div i18n="@@HOBBY_GAMING">message</div>
<div i18n="@@HOBBY_TV">message</div>
<div i18n="@@HOBBY_BOOKS">message</div>
<div i18n="@@HOBBY_HITEK">message</div>
<div i18n="@@HOBBY_PHOTO">message</div>
<div i18n="@@HOBBY_SHOPPING">message</div>
<div i18n="@@PWD_WRONG_COUNTRY">message</div>
<div i18n="@@PWD_LINK_EXPIRED">message</div>
<div i18n="@@PWD_NO_USER_FOUND">message</div>
<div i18n="@@PWD_PASSWORD_UPDATED_SUCCESSFULLY">message</div>
<div i18n="@@PWD_PASSWORD_INVALID">message</div>
<div i18n="@@PWD_PASSWORDS_NOT_MATCH">message</div>
<div i18n="@@PWD_PAGE_TITLE">message</div>
<div i18n="@@PWD_CLOSE_MODAL">message</div>
<div i18n="@@PWD_CHANGE_PWD_LABEL">message</div>
<div i18n="@@PWD_PASSWORD">message</div>
<div i18n="@@PWD_REQUIRED">message</div>
<div i18n="@@PWD_CONFIRM_PASSWORD">message</div>
<div i18n="@@PWD_RESET_PASSWORD">message</div>
<div i18n="@@ConnexionLoginEmpty">message</div>
<div i18n="@@ConnexionPasswordEmpty">message</div>
<div i18n="@@BadLogin">message</div>
<div i18n="@@BadCredentials">message</div>
<div i18n="@@AccountDeleted">message</div>
<div i18n="@@UnknownErrorSignIn">message</div>
<div i18n="@@AccountBanned">message</div>
<div i18n="@@badEmailFormat">message</div>
<div i18n="@@ConnexionErrorService">message</div>
<div i18n="@@MyProfileEmailUnique">message</div>
<div i18n="@@NonSubscriber">message</div>
<div i18n="@@NotEmailOrPhoneError">message</div>
<div i18n="@@NotEnoughCredit">message</div>
<div i18n="@@PasswordRecuperationWrongEmail">message</div>
<div i18n="@@PasswordRecuperationWrongEmailOrPhone">message</div>
<div i18n="@@PseudoIsNumeric">message</div>
<div i18n="@@shortLogin">message</div>
<div i18n="@@TooShortPassword">message</div>
<div i18n="@@UnavailabilityService">message</div>
<div i18n="@@uniqueLogin">message</div>
<div i18n="@@ValidationInvalidPhoneNumber">message</div>
<div i18n="@@ValidationRequiredEmail">message</div>
<div i18n="@@ValidationUniquePhoneNumber">message</div>
<div i18n="@@PWD_SMS_SEND_RESET_PASSWORD_TEXT">message</div>
<div i18n="@@PWD_SMS_SEND_CONFIRM_TEXT">message</div>
<div i18n="@@FORBIDDEN">message</div>
<div i18n="@@ConnexionIDAuto">message</div>
</ng-template> -->
