import { Component, OnInit } from '@angular/core';
import { UserSearchService } from '../services/user-search.service';
import { environment } from 'src/environments/environment';
import { User } from '../shared/interfaces/user';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  slideActive = 'welcome';
  members: User[];
  thumbSize: number;
  apps: boolean;
  playstore: string;
  desktop = false;
  URL = environment.LP;
  linkedSite = environment.linkedSite;
  isAt = null;

  constructor(private users: UserSearchService) {
    this.apps = environment.home.app.available;
    this.playstore = environment.home.app.linkGoogle;
    this.isAt = environment.extCode === 'AT';
  }

  ngOnInit() {
    console.log(this.linkedSite, environment.linkedSite);
    this.desktop = window.innerWidth > 960;
    const w = window.innerWidth / Math.floor(window.innerWidth / 100);
    const h = window.innerHeight / Math.floor(window.innerHeight / 160);
    this.thumbSize = window.innerWidth < 960 ? w : h;

    this.users.presentationList().subscribe(res => {
      this.members = res;
    });
  }
  changeSlide(name: string) {
    this.slideActive = name;
  }
  goToStore() {
    console.log('store');
  }
}
