import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import * as io from 'socket.io-client';
import { ChatMessageService } from './chat-message.service';
import { User } from '../shared/interfaces/user';
import { Message } from '../shared/interfaces/message';

/**
 * Chat service
 */
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  public userConnected = false;

  private socket: SocketIOClient.Socket;

  private prefix = environment.extCode;

  readonly interlocutor = new BehaviorSubject<User>(null);

  readonly lastMessage = new Subject<Message>();

  constructor(private messages: ChatMessageService) {}

  private roomName(users: number[]): string {
    let privateRoomName = this.prefix;
    users
      .sort((user1, user2) => user1 - user2)
      .map(user => {
        privateRoomName += `-${user}`;
      });
    return privateRoomName;
  }

  connect(user: User): void {
    this.userConnected = true;
    this.socket = io(environment.chat, {
      query: {
        id: user.id
      }
    });
  }

  disconnect(): void {
    this.userConnected = false;
    this.socket.disconnect();
  }

  joinRoom(user: User, interlocutor: User, limit = 10, offset = 0): Observable<Message[]> {
    const room = this.roomName([user.id, interlocutor.id]);
    this.socket.emit('user join room', room);
    return this.messages.conversationMessages(interlocutor, limit, offset);
  }

  leaveRoom(user: User, interlocutor: User): void {
    if (user && interlocutor) {
      const room = this.roomName([user.id, interlocutor.id]);
      this.socket.emit('user leave room', room);
    }
  }

  sendMessage(message: Message, interlocutor: number): void {
    interlocutor = Number(interlocutor);
    const room = this.roomName([message.sender, interlocutor]);
    console.log(room);
    this.socket.emit('notification', message, interlocutor);
    this.socket.emit('message', message, room);
  }

  typing(user: User, interlocutor: User) {
    const room = this.roomName([user.id, interlocutor.id]);
    this.socket.emit('user start typing', room);
  }

  stopTyping(user: User, interlocutor: User) {
    const room = this.roomName([user.id, interlocutor.id]);
    this.socket.emit('user stop typing', room);
  }

  get onLogin(): Observable<User> {
    return new Observable<User>(observer => {
      if (!this.socket) {
        observer.error(new Error('You are not connected to socket server'));
      }
      this.socket.on('login', user => observer.next(user));
    });
  }

  get onConnected(): Observable<User> {
    return new Observable<User>(observer => {
      if (!this.socket) {
        observer.error(new Error('You are not connected to socket server'));
      }
      this.socket.on('user connected', user => observer.next(user));
    });
  }

  get onMessage(): Observable<Message> {
    return new Observable(observer => {
      if (!this.socket) {
        observer.error(new Error('You are not connected to socket server'));
      }
      this.socket.on('message', res => {
        if (!observer.closed) {
          observer.next(res);
          this.lastMessage.next(res);
        }
      });
    });
  }

  get onNotification(): Observable<Message> {
    return new Observable(observer => {
      if (!this.socket) {
        observer.error(new Error('You are not connected to socket server'));
      }
      this.socket.on('notification', res => observer.next(res));
    });
  }

  get onTyping(): Observable<User> {
    console.log('chat service on typing');
    return new Observable(observer => {
      if (!this.socket) {
        observer.error(new Error('You are not connected to socket server'));
      }
      console.log(observer);
      this.socket.on('user start typing', res => observer.next(res));
    });
  }

  get onTypingStop(): Observable<User> {
    return new Observable(observer => {
      if (!this.socket) {
        observer.error(new Error('You are not connected to socket server'));
      }
      this.socket.on('user stop typing', res => observer.next(res));
    });
  }

  get onUserLogout(): Observable<User> {
    return new Observable(observer => {
      if (!this.socket) {
        observer.error(new Error('You are not connected to socket server'));
      }
      this.socket.on('user logout', res => observer.next(res));
    });
  }

  get onUserLogin(): Observable<User> {
    return new Observable(observer => {
      if (!this.socket) {
        observer.error(new Error('You are not connected to socket server'));
      }
      this.socket.on('user login', res => observer.next(res));
    });
  }

  get onError(): Observable<Error> {
    return new Observable(observer => {
      if (!this.socket) {
        observer.error(new Error('You are not connected to socket server'));
      }
      this.socket.on('error', res => observer.next(res));
    });
  }

  get onReconnect(): Observable<number> {
    return new Observable(observer => {
      if (!this.socket) {
        observer.error(new Error('You are not connected to socket server'));
      }
      this.socket.on('reconnect', res => observer.next(res));
    });
  }
}
